import { defineStore } from "pinia";

interface LoaderState {
  enabled: boolean;
}

export const useLoaderStore = defineStore("loader", {
  state: (): LoaderState => ({
    enabled: false,
  }),
  actions: {
    enable(): void {
      this.enabled = true;
    },
    disable(): void {
      this.enabled = false;
    },
    isEnabled(): boolean {
      return this.enabled;
    },
  },
});
