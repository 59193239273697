import { ref, watch } from "vue";

const breakpoints: {
  [size: string]: number;
} = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

const screenWidth = ref(window.innerWidth);
window.onresize = () => {
  screenWidth.value = window.innerWidth;
};

export default (args: string) => {
  const show = ref(true);
  let defaultDisplay = "block";
  const displays = ["block", "hidden"];
  const minSizes: string[] = [];

  const filters = args.split(" ");
  filters.forEach((val) => {
    const [size, type] = val.split(":");
    if (!type && displays.includes(size)) {
      defaultDisplay = size;
    }

    if (breakpoints[size] && displays.includes(type)) {
      minSizes[breakpoints[size]] = type;
    }
  });

  const determineDisplay = (width: number) => {
    let display = defaultDisplay;
    minSizes.forEach((type, size) => {
      if (width >= size) {
        display = type;
      }
    });

    show.value = display === "block";
  };

  determineDisplay(screenWidth.value);
  watch(() => screenWidth.value, determineDisplay, { deep: true });

  return show.value;
};
