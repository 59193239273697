<template>
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.47704 2 2 6.47724 2 12C2 17.5228 6.47724 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47724 17.5228 2 12 2ZM12 4.85714C12.526 4.85714 12.9524 5.28344 12.9524 5.80959V6.4049C14.2005 6.68272 15.1581 7.46219 15.6757 8.32454C15.9328 8.75303 15.7767 9.37684 15.3482 9.63413C14.9197 9.89123 14.2957 9.73518 14.0386 9.30668C13.7297 8.79189 12.9791 8.19061 12 8.19061C11.38 8.19061 10.8849 8.36859 10.5714 8.62209C10.2579 8.8756 10.0953 9.17235 10.0953 9.61918C10.1011 10.454 11.4892 10.761 12.3571 11.1073C12.9965 11.3599 13.7433 11.5522 14.4554 11.9853C15.1674 12.4185 15.8096 13.2968 15.8096 14.381C15.8096 15.3767 15.3466 16.2749 14.6339 16.8513C14.1548 17.2388 13.5806 17.4987 12.9524 17.6252V18.1906C12.9524 18.7166 12.5261 19.1431 12 19.1431C11.474 19.1431 11.0476 18.7166 11.0476 18.1906V17.5953C9.79954 17.3137 8.84173 16.5233 8.32434 15.6607C8.06724 15.2322 8.22309 14.6082 8.65179 14.3511C9.08028 14.094 9.70408 14.2499 9.96138 14.6786C10.2703 15.1934 11.0209 15.8096 12 15.8096C12.62 15.8096 13.1151 15.6167 13.4286 15.3632C13.7421 15.1097 13.9047 14.8279 13.9047 14.381C13.8989 13.5296 12.5174 13.2257 11.6429 12.8781C11.0035 12.6256 10.2567 12.4333 9.54464 12.0002C8.83255 11.5669 8.19041 10.7034 8.19041 9.61918C8.19041 8.62347 8.65338 7.71051 9.36607 7.13413C9.84479 6.7469 10.4197 6.4974 11.0476 6.3752V5.8098C11.0476 5.28383 11.4739 4.85735 12 4.85735V4.85714Z"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MoneySolidIcon",
});
</script>
