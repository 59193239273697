import { useLoaderStore } from "@/store/LoaderStore";

export class LoaderHandler {
  static enable(): void {
    useLoaderStore().enable();
  }

  static disable(): void {
    useLoaderStore().disable();
  }
}
