export default class StringHandler {
  static getLength(str: string): number {
    return str ? str.replace(/\n/g, "\r\n").trim().length : 0;
  }

  static toCamelCase(str: string): string {
    return str
      .toLowerCase()
      .split("_")
      .map((str: string, key: number) =>
        !key ? str : str.charAt(0).toUpperCase() + str.slice(1)
      )
      .join("");
  }

  static replaceSpace(str: string): string {
    return str.replace(/\s/g, "%20");
  }

  static stringArrayToUpperCase(strArray: string[]): string[] {
    return strArray.map((str: string) => str.toUpperCase());
  }

  static stringArrayWithComma(strArray: string[]): string {
    return strArray.join(", ");
  }

  static formatBytes = (bytes: number): string => {
    const units: string[] = [
      "bytes",
      "KB",
      "MB",
      "GB",
      "TB",
      "PB",
      "EB",
      "ZB",
      "YB",
    ];
    let l = 0;
    let n: number = parseInt(bytes.toString(), 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  };
}
