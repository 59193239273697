import { defineStore } from "pinia";

export type FlagTypes = "applicant_invited" | "applicant_approved";
export interface FlagSocket {
  creator_id: number;
  flag_type: FlagTypes;
  object_type: number;
  object_id: number;
  value: boolean;
}
export interface FlagSocketState {
  flagsApproved: FlagSocket[];
  flagsInvited: FlagSocket[];
}
export const useFlagSocketStore = defineStore("flagSocket", {
  state: (): FlagSocketState => ({
    flagsApproved: [] as FlagSocket[],
    flagsInvited: [] as FlagSocket[],
  }),
  actions: {
    update(flag: FlagSocket): FlagSocket {
      if (flag.flag_type === "applicant_approved") {
        // Check if exists the id and updated the value else create the record
        const index = this.flagsApproved.findIndex(
          (record) =>
            record.object_id === flag.object_id &&
            record.object_type === flag.object_type
        );
        if (index === -1) {
          this.flagsApproved.push(flag);
        } else {
          this.flagsApproved[index].value = flag.value;
        }
      } else if (flag.flag_type === "applicant_invited") {
        // Check if exists the id and updated the value else create the record
        const index = this.flagsInvited.findIndex(
          (record) =>
            record.object_id === flag.object_id &&
            record.object_type === flag.object_type
        );
        if (index === -1) {
          this.flagsInvited.push(flag);
        } else {
          this.flagsInvited[index].value = flag.value;
        }
      }
      return flag;
    },
  },
});
