import type { App } from "vue";
import Grid from "@/core/shared/helpers/Grid";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    /**
     * @remarks
     * The responsive Grid with tailwind breakpoints.
     *@methods $grid('block xl:hidden')
     */
    // eslint-disable-next-line
    $grid: typeof Grid;
  }
}

export default {
  install(app: App) {
    // configure the app
    app.config.globalProperties.$grid = Grid;
  },
};
