<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 7.99996C18 12.4182 13.9703 15.9999 9 15.9999C7.40255 16.0067 5.827 15.6226 4.40662 14.8799L0 15.9999L1.50525 12.4308C0.554625 11.1622 0 9.6388 0 7.99996C0 3.58169 4.02975 0 9 0C13.9703 0 18 3.58169 18 7.99996ZM5.625 6.8571H3.375V9.14281H5.625V6.8571ZM14.625 6.8571H12.375V9.14281H14.625V6.8571ZM7.875 6.8571H10.125V9.14281H7.875V6.8571Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessagesSolidIcon",
});
</script>
