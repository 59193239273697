export class Onboarding {
  static readonly PERSONAL_INFORMATION = 1;
  static readonly ABOUT_ME = 2;
  static readonly SOCIAL_NETWORKS = 3;
  static readonly CATEGORIES = 4;
  static readonly SAMPLE_PHOTO = 5;
  static readonly SAMPLE_VIDEO = 6;
  static readonly DISCLAIMER = 7;
  static readonly SUMMARY = 8;
  static readonly APPLICATION_SENT = 9;
  static readonly APPLICATION_APPROVED = 10;

  static getSteps() {
    return [
      {
        path: "personal-information",
        name: "PersonalInformation",
        position: Onboarding.PERSONAL_INFORMATION,
      },
      {
        path: "about-me",
        name: "AboutMe",
        position: Onboarding.ABOUT_ME,
      },
      {
        path: "social-networks",
        name: "SocialNetworks",
        position: Onboarding.SOCIAL_NETWORKS,
      },
      {
        path: "categories",
        name: "Categories",
        position: Onboarding.CATEGORIES,
      },
      {
        path: "sample-photo",
        name: "SamplePhoto",
        position: Onboarding.SAMPLE_PHOTO,
      },
      {
        path: "sample-video",
        name: "SampleVideo",
        position: Onboarding.SAMPLE_VIDEO,
      },
      {
        path: "disclaimer",
        name: "Disclaimer",
        position: Onboarding.DISCLAIMER,
      },
      {
        path: "summary",
        name: "Summary",
        position: Onboarding.SUMMARY,
      },
      {
        path: "application-sent",
        name: "ApplicationSent",
        position: Onboarding.APPLICATION_SENT,
      },
    ];
  }
}
