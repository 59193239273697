import { defineStore } from "pinia";

export interface ApiState {
  maintenance: boolean;
  connection: {
    online: boolean;
  };
}

export const useApiStore = defineStore("api", {
  state: (): ApiState => ({
    maintenance: false,
    connection: {
      online: true,
    },
  }),
  actions: {
    setMaintenanceOn(): void {
      this.maintenance = true;
    },
    setMaintenanceOff(): void {
      this.maintenance = false;
    },
    setOnline(): void {
      this.connection.online = true;
    },
    setOffline(): void {
      this.connection.online = false;
    },
  },
});
