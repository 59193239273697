import { defineStore } from "pinia";

export interface NetworkState {
  connection: {
    online: boolean;
  };
}

export const useNetworkStore = defineStore("network", {
  state: (): NetworkState => ({
    connection: {
      online: true,
    },
  }),
  actions: {
    setOnline(): void {
      this.connection.online = true;
    },
    setOffline(): void {
      this.connection.online = false;
    },
  },
});
