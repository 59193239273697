import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default class RecurringJobGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withRecurringJob(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (from.query) {
      to.meta.query = from.query;
    }

    next();
  }
}
